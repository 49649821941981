.swiper {
  width: 100%;
  /* height: 100%; */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.myproduct {
  display: inline-grid;
  margin-left: auto;
  margin-right: auto;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mythumSwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mythumSwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mythumSwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swal2-popup {
  border: 1px solid #c0bfba;
  background-color: black;
  border-radius: 25px;
  width: 42%;
}

@media only screen and (max-width: 1024px) {
  .swal2-popup {
    width: 80% !important;
  }
}

@media only screen and (max-width: 480px) {
  .swal2-popup {
    width: 85% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.swal2-title {
  text-align: start;
}

.swal2-title,
.swal2-html-container {
  color: white;
  font-family: GeneralSans;
}

.swal2-titlefa {
  text-align: end;
}

.swal2-titlefa,
.swal2-html-containerfa {
  color: white;
  font-family: Fedrasemibold;
}

.confirmButton {
  background-color: #00d777;
  color: black;
  justify-self: flex-end;
  border-radius: 25px;
  padding: 12px 32px 12px 32px;
}

/* jobform */
.file-upload {
  text-align: center;
  padding: 4.5rem;
  cursor: pointer;
}

.file-upload input {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.option {
  background-color: #d3defc !important;
}
.option:hover {
  background-color: #a6bdfa !important;
}

.secondtitlefa {
  text-align: center;
  font-family: Fedrasemibold;
}

.secondtitle {
  text-align: center;
  font-family: GeneralSans;
}

.confirmbuttonfa {
  background-color: #f27474;
  border-radius: 25px;
  padding: 12px 32px 12px 32px;
  color: black;
}

.confirmbutton {
  background-color: #f27474;
  border-radius: 25px;
  padding: 12px 32px 12px 32px;
  color: black;
}

.projectimg {
  width: 60% !important;
}
