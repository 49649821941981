@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: GeneralSans;
  src: local("GeneralSans"),
    url("../../Fonts/GeneralSans-Variable.ttf") format("truetype");
}

@font-face {
  font-family: FedraLight;
  src: local("FedraLightt"),
    url("../../Fonts/Fedra-Sans\ AR\ +\ LT\ 1.ttf") format("truetype");
}

@font-face {
  font-family: FedraMedium;
  src: local("FedraMedium"),
    url("../../Fonts/Fedra-Sans\ AR\ +\ LT\ 2.ttf") format("truetype");
}

@font-face {
  font-family: FedraRegular;
  src: local("FedraRegular"),
    url("../../Fonts/Fedra-Sans\ AR\ +\ LT\ 3.ttf") format("truetype");
}

@font-face {
  font-family: Fedrabold;
  src: local("Fedrabold"),
    url("../../Fonts/Fedra-Sans\ AR\ +\ LT\ 4.ttf") format("truetype");
}

@font-face {
  font-family: Fedrasemibold;
  src: local("Fedrasemibold"),
    url("../../Fonts/Fedra-Sans\ AR\ +\ LT\ 5.ttf") format("truetype");
}

.PersianFont {
  font-family: Fedrasemibold;
  direction: rtl;
}

.PersianBold {
  font-family: Fedrabold;
}

.PersianMed {
  font-family: FedraMedium;
}

.EnglishFont {
  font-family: GeneralSans;
}

.App {
  text-align: center;
  background-color: #000;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bghome {
  background-image: url("../../Images/Home.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
}

.bgproduct {
  background-image: url("../../Images/Products-min.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
}

.bgdetail {
  background-image: url("../../Images/SingleProduct-min.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
}

.bgfound {
  background-image: url("../../Images/Error405-min.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
}

.bgaction {
  background-image: url("../../Images/InAction-min.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
}

.bgjob {
  background-image: url("../../Images/JobBoard-min.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
}

.bgjobform {
  background-image: url("../../Images/jobform.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
}

.bgGallery {
  background-image: url("../../Images/bgGallery.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
}

.bgpost {
  background-image: url("../../Images/bgpost.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .css-i4bv87-MuiSvgIcon-root{
   width:10px !important;
} */

.rtl {
  direction: rtl;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
